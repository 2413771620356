#globalNav {
  height: 60px;
  background: black;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding-block: 2rem;
  @media screen and (max-width: 1366px) {
    display: none;
  }
}
.globalNav_top {
  height: 100%;
}

#globalNav a {
  color: white;
  text-decoration: none;
  font-size: 0.7rem;
  font-weight: 500;
  &:focus,
  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
}

#globalNav img {
  width: 175px;
}

#globalNav .globalNav_top a.button {
  background: $primary-color;
  color: #fff;
  border: 1px solid $primary-color;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  border-radius: 8px;
  &:focus,
  &:hover {
    color: black;
    background: #fff;
    border: 1px solid black;
    text-decoration: none;
  }
}
.globalNav_top_content {
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  transition: all 0.3s;
  // max-width: 1024px;
  margin: 0 auto;
  padding-left: 4rem;
  height: 100%;
}
.globalNav_sub {
  transition: opacity ease 0.3s;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.globalNav_main:hover .globalNav_sub {
  opacity: 1;
  height: auto;
  background-color: #000;
  width: 100%;
  position: absolute;
  left: 0;
  line-height: 2;
  // padding: 1rem 0;
}
.globalNav_sub_products {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  margin: 1rem auto;
  height: 100%;
  justify-content: space-evenly;
  text-align: center;
}

.wave-animation-top,
.wave-animation-bottom {
  position: absolute;
  width: 100%;
  height: 51px;
  z-index: 2;
  //margin-top: calc(-0.3rem + 1px - 25px); // 0.3rem comes from padding of the panel
  //margin-bottom: calc(-0.3rem - 1px);
  color: #fff;
  transform: rotate(0);

  > svg {
    display: block;
    width: 100%;
    height: 100%;

    g {
      animation-name: wave;
      animation-duration: 15s;
      animation-timing-function: linear;
      //animation-delay: 0;
      //animation-direction: normal;
      animation-iteration-count: infinite;
      //animation-fill-mode: forwards;
      //animation-play-state: running;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 21px;
  }
}

.wave-animation-top {
  top: -25px;
  height: 26px;
  @include media-breakpoint-down(sm) {
    top: -10px;
    height: 11px;
  }
}

.wave-animation-bottom {
  bottom: -50px;
  > svg {
    g {
      animation-delay: -1s;
    }
  }
  @include media-breakpoint-down(sm) {
    bottom: -20px;
  }
}

@keyframes wave {
  0% {
    transform: matrix(1, 0, 0, 1, -2880, 0);
  }
  100% {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.contentPanel {
  background: white;
  padding: 1rem 0;
  position: relative;
  word-break: break-word;
  background-size: 100%;
  background-repeat: repeat;
  background-position: center;

  > * {
    z-index: unset;
    position: relative;
  }

  h2 a.button {
    font-size: 2.6rem;
  }

  h3 a.button {
    font-size: 2.2rem;
  }

  h4 a.button {
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  &.commonFooter:before,
  &.bottomPanel:before {
    display: none;
  }

  a.button {
    color: white;
    background: $primary-color;
    border: 1px solid $primary-color;
    padding: 8px 24px;
    font-weight: $weight-bold;
    border-radius: 8px;
    &:focus,
    &:hover {
      background: white;
      color: black;
      border: 1px solid black;
      text-decoration: none;
    }
    @media screen and (max-width: 600px) {
      font-size: 13px;
    }
  }

  a.p-button {
    color: white;
    background: #0158d7;
    border: 1px solid #0158d7;
    padding: 8px 56px 8px 32px;
    font-weight: 700;
    border-radius: 8px;
    position: relative;
    transition: all 0.2s;
    &:focus,
    &:hover {
      background: white;
      color: black;
      border: 1px solid black;
      text-decoration: none;
    }
  }

  a.p-button::after {
    content: url("./CT-Filtration-Icons-arrow.svg");
    position: absolute;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    top: 8px;
    color: white;
  }

  a.p-button:hover::after {
    content: url("./CT-Filtration-Icons-arrow-black.svg");
  }

  // a.button {
  //   border: none;
  //   display: block;
  //   text-align: center;
  //   cursor: pointer;
  //   text-transform: uppercase;
  //   outline: none;
  //   overflow: hidden;
  //   position: relative;
  //   color: #fff;
  //   font-weight: 700;
  //   font-size: 15px;
  //   background-color: #222;
  //   padding: 17px 60px;
  //   margin: 0 auto;
  //   width: 35%;
  //   box-shadow: 0 5px 15px rgba(0,0,0,0.20);
  //   text-decoration: none;
  // }

  // a.button span {
  //   position: relative;
  //   z-index: 1;
  // }

  // a.button::after {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   height: 490%;
  //   width: 140%;
  //   background: #78c7d2;
  //   -webkit-transition: all .5s ease-in-out;
  //   transition: all .5s ease-in-out;
  //   -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
  //   transform: translateX(-98%) translateY(-25%) rotate(45deg);
  // }

  // a.button:hover::after {
  //   -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
  //   transform: translateX(-9%) translateY(-25%) rotate(45deg);
  // }
}

.t-button {
  color: black;
  background: #fff;
  border: 1px solid #000;
  padding: 0.2rem 1rem;
  font-weight: $weight-bold;
  border-radius: 20px;
  &:focus,
  &:hover {
    background: black;
    color: #fff;
    text-decoration: none;
  }
}

.contentPanel--dark {
  background: linear-gradient(rgba(#000, 1), rgba(#000, 0.7));
  color: white;
}

.contentPanel__bg {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .contentPanel--dark &:before {
    background: rgba(#fff, 0.05);
  }
}

//override bootstrap container size
.container {
  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
}

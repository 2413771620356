.main-content {
  // padding-top: map-get($top-bar-height, mobile);

  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  // }
  overflow: hidden;
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem;
    padding: 8px 24px;
    border-radius: 8px;
    color: white;
    background: $primary-color;
    border: 1px solid $primary-color;
    font-weight: $weight-bold;
    &:hover {
      background: transparent;
      color: black;
    }
  }
}

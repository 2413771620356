// overriding bootstrap variables in _boostrap-override.scss
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

$family-base: "Montserrat", sans-serif;
$family-header: "Montserrat", sans-serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: 300;
$weight-bold: 700;

// colors - sister colours from bootrap
// $color-blue: #548dd4;
$color-blue: #4169e1;
$color-blue-new: #0158D7;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #27b90f;

// more colors
$color-gold: #faa222;
$color-dark-gray: #3b3b3b;
$color-light-gray: #ebebeb;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-blue-new;
$font-color: $color-dark-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $primary-color;
$link-hover-color: $color-green;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  //mobile: 50px,
  mobile: 44px,
  //small-device: 56px,
  small-device: 44px,
  tablet: 56px,
  desktop: 116px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
